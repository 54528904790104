import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Plan iOpos</Title>
                <Text>
                    PLAN TOTAL IOPOS
                    <br />
                    <br />
                    Us presentem un plan renovat fins i tot més complet.
                    <br />
                    <br />
                    Si et prepares per a Mossos d'Esquadra, Guàrdia Urbana i/o policies locals
                    aquest plan és per a tú.
                    <br />
                    <br />
                    Un plan de formació personalitzada on pots tenir tota la formació de iOpos al
                    teu abast.
                    <br />
                    <br />
                    Sí si, has escoltat be! tota la formació durant dotze mesos.
                    <br />
                    <br />
                    A més assessorament i planificació per a aconseguir els teus objectius més
                    ràpid.
                    <br />
                    <br />
                    Què és Plan iOpos?
                    <br />
                    <br />
                    És una tarifa que s'activa des del moment en què et matricules fins passats
                    dotze mesos.
                    <br />
                    <br />
                    Què puc fer amb Plan iOpos?
                    <br />
                    <br />
                    Durant dotze mesos pots utilitzar qualsevol producte nostre.
                    <br />
                    <br />
                    A què tinc accés?
                    <br />
                    <br />
                    Pots agafar qualsevol aplicació, llibre, curs en línia o presencial (en els
                    presencials sense repetir el format).
                    <br />
                    <br />
                    Puc agafar aquesta tarifa entre diversos usuaris?
                    <br />
                    <br />
                    No, aquesta tarifa és personal i intransferible i no pot utilitzar-se en
                    diversos dispositius al mateix temps.
                    <br />
                    <br />
                    En quant està valorat tot el Plan?
                    <br />
                    <br />
                    L'adquisició de tot està valorada en més de 3.000 euros.
                    <br />
                    <br />
                    Exactament, a què puc optar?
                    <br />
                    <br />
                    ▪️ Mòdul d'exàmens oficials
                    <br />
                    <br />
                    ▪️ Mòdul actualitat
                    <br />
                    <br />
                    ▪️ Mòdul de Guàrdia Urbana
                    <br />
                    <br />
                    ▪️ Mòdul de policia local
                    <br />
                    <br />
                    ▪️ Mòdul de Mossos d'Esquadra
                    <br />
                    <br />
                    ▪️ Mòdul de psicotècnics
                    <br />
                    <br />
                    ▪️ Pòdcast
                    <br />
                    <br />
                    ▪️ Curs presencial Mossos d'Esquadra
                    <br />
                    <br />
                    ▪️ Curs presencial Guàrdia Urbana de Barcelona
                    <br />
                    <br />
                    ▪️ Curs en línia de Mossos d'Esquadra 2022-23
                    <br />
                    <br />
                    ▪️ Curs en línia de Guàrdia Urbana 2022-23
                    <br />
                    <br />
                    ▪️ Curs en línia de Policia local
                    <br />
                    <br />
                    ▪️ Qualsevol curs en línia de policies locals
                    <br />
                    <br />
                    ▪️ Curs presencial de psicotècnics aptitudinals
                    <br />
                    <br />
                    ▪️ Curs en línia de psicotècnics aptitudinals
                    <br />
                    <br />
                    ▪️ Curs presencial de test de personalitat
                    <br />
                    <br />
                    ▪️ Preparació de proves físiques dos mesos
                    <br />
                    <br />
                    ▪️ Curs online d'anglès
                    <br />
                    <br />
                    ▪️ Curs presencial de casos pràctics
                    <br />
                    <br />
                    ▪️ Curs en línia de casos pràctics
                    <br />
                    <br />
                    ▪️ Llibres (fins a dos llibres al mes)
                    <br />
                    <br />
                    ▪️ Seminaris
                    <br />
                    <br />
                    ▪️ Classes magistrals
                    <br />
                    <br />
                    ▪️ Abonament de 10 classes de proves físiques a uns dels nostres centres.
                    <br />
                    <br />
                    Amb aquest plan ja no haig de pagar res més?
                    <br />
                    <br />
                    Aquesta és la idea. Amb un únic pagament, que tingueu una formació integral
                    durant dotze mesos.
                    <br />
                    <br />
                    Quant val el Plan iOpos?
                    <br />
                    <br />
                    1.499,99 € fraccionat en tres pagaments a través de PayPal
                    <br />
                    <br />
                    Únic requisit: No repetir cursos de la mateixa formació.
                    <br />
                    <br />
                    El 95% d'alumnes que tenen el plan són aptes.
                    <br />
                    <br />
                    Opositar mai havia estat tan fàcil!
                    <br />
                    <br />
                    Recorda, accés directe al ISPC
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/Dgbwt-mDR00"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>

            {/** ¿Cómo accedo? */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>¿Cómo accedo?</Title>
                    <Text className="text-justify">
                        Una vez adquirido a través de la sección{" "}
                        <Link to="/tienda/" className="default-link">
                            Tienda
                        </Link>{" "}
                        recibirás un correo con tus credenciales para poder acceder al curso. En el
                        menú superior encontrarás la opción{" "}
                        <a
                            href="https://cursos.iopos.es"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="default-link"
                        >
                            Plataforma de cursos
                        </a>{" "}
                        que te dirigirá hacia la plataforma donde están almacenados todos los cursos
                        y formaciones.
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
